import React, { useEffect, useState } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Sidebar from "./Component/SideBar/SideBar"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import Login from "./Component/Login/Login"
import PrivateComponent from './Component/PrivateComponent/PrivateComponent';
import NotFound from './Component/NotFound'
import SessionExpiredPopup from './Component/Popup/SessionPopup';
import axios from 'axios';
import { useIdleTimer } from 'react-idle-timer'; // Import the useIdleTimer hook

function App() {
  const [state, setstate] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(false);

  // Set up Axios interceptors here
  useEffect(() => {
    const axiosInterceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          // Handle the 401 error here
          setSessionExpired(true);
        }
        return Promise.reject(error);
      }
    );

    // Clean up the interceptor when the component unmounts
    return () => {
      axios.interceptors.response.eject(axiosInterceptor);
    };
  }, []);

  const handleClose = async () => {
    if (sessionExpired === true) {
      setSessionExpired(false);
    }
  };

  // Use the useIdleTimer hook to manage the idle timer
  const { reset } = useIdleTimer({
    timeout: 15 * 60 * 1000, // 10 minutes (10 * 60 seconds * 1000 milliseconds)
    onIdle: () => setSessionExpired(true),
  });

  useEffect(() => {
    if (sessionExpired === true) {
      reset(); // Reset the timer when session expires
    }
  }, [sessionExpired, reset]);

  return (
    <div className="App">
      <BrowserRouter>
        {sessionExpired && (
          <div className="background-blur"></div>
        )}
        {sessionExpired === true ? (
          <SessionExpiredPopup visible={sessionExpired} onClose={handleClose} />
        ) : (
          <Routes>
            <Route path='/' element={<Login setstate={setstate} state={state} />} />
            <Route element={<PrivateComponent state={state} />}>
              <Route exact path="/sidebar/*" element={<Sidebar />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        )}
      </BrowserRouter>
    </div>
  );
}

export default App;


// import React, { useEffect, useState } from 'react';
// import './App.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import Sidebar from "./Component/SideBar/SideBar"
// import { BrowserRouter, Route, Routes } from "react-router-dom"
// import Login from "./Component/Login/Login"
// import PrivateComponent from './Component/PrivateComponent/PrivateComponent';
// import NotFound from './Component/NotFound'
// import SessionExpiredPopup from './Component/Popup/SessionPopup';
// import axios from 'axios';

// function App() {
//   const [state, setstate] = useState(false);
//   const [sessionExpired, setSessionExpired] = useState(false);
//   const [inactivityTimer, setInactivityTimer] = useState(null);

//   // Function to reset the inactivity timer
//   const resetInactivityTimer = () => {
//     clearTimeout(inactivityTimer);
//     const newInactivityTimer = setTimeout(() => {
//       setSessionExpired(true);
//     }, 10 * 60 * 1000); // 10 minutes (10 * 60 seconds * 1000 milliseconds)
//     setInactivityTimer(newInactivityTimer);
//   };

//   // Set up Axios interceptors here
//   useEffect(() => {
//     const axiosInterceptor = axios.interceptors.response.use(
//       (response) => response,
//       (error) => {
//         if (error.response && error.response.status === 401) {
//           // Handle the 401 error here
//           setSessionExpired(true);
//         }
//         return Promise.reject(error);
//       }
//     );

//     // Clean up the interceptor when the component unmounts
//     return () => {
//       axios.interceptors.response.eject(axiosInterceptor);
//     };
//   }, []);

//   // Set up event listeners to reset the inactivity timer
//   useEffect(() => {
//     const resetTimerOnActivity = () => {
//       resetInactivityTimer();
//     };

//     window.addEventListener('mousemove', resetTimerOnActivity);
//     window.addEventListener('keydown', resetTimerOnActivity);

//     return () => {
//       window.removeEventListener('mousemove', resetTimerOnActivity);
//       window.removeEventListener('keydown', resetTimerOnActivity);
//     };
//   }, []);

//   const handleClose = async () => {


//     if (sessionExpired === true) {
//       setSessionExpired(false)
//       resetInactivityTimer();
//     }
//   };

//   return (
//     <div className="App">
//       {sessionExpired && (
//         <div className="background-blur"></div>
//       )}
//       <BrowserRouter>
//         {sessionExpired === true ? (
//           <SessionExpiredPopup visible={sessionExpired} onClose={handleClose} />
//         ) : (
//           <Routes>
//             <Route path='/' element={<Login setstate={setstate} state={state} />} />
//             <Route element={<PrivateComponent state={state} />}>
//               <Route exact path="/sidebar/*" element={<Sidebar />} />
//             </Route>
//             <Route path="*" element={<NotFound />} />
//           </Routes>
//         )}
//       </BrowserRouter>
//     </div>
//   );
// }

// export default App;
