import React, { useState } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
  RadioGroup,
  Radio,
  FormControlLabel,
  Button,
  TextField,
} from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import { FormsData } from "../formData";
import axios from "axios";
import { BACKEND_URI } from "../../../config/config";
export default function LausdForm({ program }) {
  const [formData, setFormData] = useState(FormsData);
  const [studentName, setStudentName] = useState("");
  console.log("selected Program", program);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value, // handle checkboxes and other inputs
    }));
  };
  let userData = JSON.parse(localStorage.getItem("studentNest"));
  const { firstName, lastName, address, email, mobileNumber } = userData;
  const token = localStorage.getItem("jwtToken");
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const body = {
      program,
      name: `${firstName} ${lastName}`,
      email,
      contact: mobileNumber,
      address,
      formData,
    };
    const response = await axios.post(`${BACKEND_URI}/feedback`, body, {
      headers,
    });
    if (response) {
      toast.success("Form Submitted Succesfully");
      setFormData(FormsData);
    }
  };
  return (
    <div className="container my-4">
      <h1 className="text-center mb-3">Tutoring Session Form</h1>
      <form
        onSubmit={handleSubmit}
        className="mw-50 mx-auto text-start"
        style={{ maxWidth: "550px" }}
      >
        <div className="form-group mb-3">
          <label className="fw-bold mb-1">Session Date:</label>
          <input
            type="date"
            name="sessionDate"
            value={formData.sessionDate}
            onChange={handleInputChange}
            className="form-control"
            required
          />
        </div>
        <div className="form-group mb-3">
          <label className="fw-bold mb-2">Select School</label>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel className="block">School Name</InputLabel>
            <Select
              size="small"
              name="schoolName"
              placeholder="School Name"
              value={formData.schoolName}
              input={<OutlinedInput label="School Name" />}
              onChange={handleInputChange}
              className="form-control"
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              required
            >
              <MenuItem value="186th St.Elementary">
                186th St.Elementary
              </MenuItem>
              <MenuItem value="Carthay ES Magnet">Carthay ES Magnet</MenuItem>
              <MenuItem value="Dayton Heights ES">Dayton Heights ES</MenuItem>
              <MenuItem value="Elizabeth LC">Elizabeth LC</MenuItem>
              <MenuItem value="Purche Ave. ES">Purche Ave. ES</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="form-group mb-3">
          <label className="d-block fw-bold mb-2">
            Students that were present: (separate the students' names with
            commas)
          </label>
          <TextField
            label="Student Name"
            name="presentStudent"
            size="small"
            value={formData.presentStudent}
            onChange={handleInputChange}
            className="form-control"
            required
          />
        </div>
        <div className="form-group mb-3">
          <label className="d-block fw-bold mb-2">
            Student(s) that were absent: (if more than one student was absent,
            separate the names with commas)
          </label>
          <TextField
            label="Student Name"
            name="absentStudent"
            size="small"
            value={formData.absentStudent}
            onChange={handleInputChange}
            className="form-control"
            required
          />
        </div>
        <div className="form-group mb-3">
          <label className="d-block fw-bold mb-1">
            Student(s) Disposition: (If you had more than one student, please
            rate the group as a whole)
          </label>
          <FormControl component="fieldset">
            <RadioGroup
              name="disposition"
              value={formData.disposition}
              onChange={handleInputChange}
              required
            >
              <FormControlLabel
                value="Responsive and engaged"
                control={<Radio />}
                label="Responsive and engaged"
              />
              <FormControlLabel
                value="Passive and non-participatory"
                control={<Radio />}
                label="Passive and non-participatory"
              />
              <FormControlLabel
                value="Serious behavior concerns"
                control={<Radio />}
                label="Serious behavior concerns"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div className="form-group mb-3">
          <label className="fw-bold mb-2">
            Please provide the Content File Name (from the Content Library) or
            provide the name of the activity/topic/skill that was covered? (If
            the student(s) worked on an assessment, include notes/summary here)
          </label>

          <TextField
            label="Content File Name / Activity / Topic"
            name="contentFileName"
            size="small"
            value={formData.contentFileName}
            onChange={handleInputChange}
            className="form-control"
            required
          />
        </div>

        <div className="text-center my-3">
          <Button
            size="medium"
            variant="contained"
            color="primary"
            type="submit"
          >
            Submit
          </Button>
        </div>
      </form>
      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
}
