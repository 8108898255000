import React from 'react';
import './Popup.css';
import { useNavigate } from 'react-router-dom';


function SessionExpiredPopup({ visible, onClose }) {
    const navigate = useNavigate()
    const handleOKClick = () => {

        localStorage.removeItem("jwtToken");
        // Navigate the user to the login route
        navigate('/');
        onClose();
    };
    if (!visible) {
        return null;
    }

    return (
        <div className="session-popup">
            <div className="popup-content">
                <p>Your session has expired! Please login again.</p>
                <button onClick={handleOKClick}>Ok</button>
            </div>
        </div>
    );
}

export default SessionExpiredPopup;
