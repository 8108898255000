import React, { useState } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
  RadioGroup,
  Radio,
  FormControlLabel,
  Button,
  TextField,
} from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import { FormsData, FusdSubjectData, GradeArray } from "../formData";
import axios from "axios";
import { BACKEND_URI } from "../../../config/config";
export default function OcdeForm({ program }) {
  const [formData, setFormData] = useState(FormsData);
  const [subjects, setSubjects] = useState(FusdSubjectData);
  const filteredGrades = GradeArray.slice(2);
  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value, // handle checkboxes and other inputs
    }));
  };
  let userData = JSON.parse(localStorage.getItem("studentNest"));
  const { firstName, lastName, address, email, mobileNumber } = userData;
  const token = localStorage.getItem("jwtToken");
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  const handleSubjectChange = (event) => {
    const { name, value, checked, type } = event.target;
    setSubjects((prevSubjects) => ({
      ...prevSubjects,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const body = {
      program,
      name: `${firstName} ${lastName}`,
      email,
      contact: mobileNumber,
      address,
      formData,
    };
    const response = await axios.post(`${BACKEND_URI}/feedback`, body, {
      headers,
    });
    if (response) {
      toast.success("Form Submitted Succesfully");
      setFormData(FormsData);
    }
  };
  return (
    <div className="container my-4">
      <h1 className="text-center mb-3">Tutoring Session Form</h1>
      <form
        onSubmit={handleSubmit}
        className="mw-50 mx-auto text-start"
        style={{ maxWidth: "550px" }}
      >
        <div className="form-group mb-3">
          <label className="fw-bold mb-2">Select School</label>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel className="block">School Name</InputLabel>
            <Select
              size="small"
              name="schoolName"
              placeholder="School Name"
              value={formData.schoolName}
              input={<OutlinedInput label="School Name" />}
              onChange={handleInputChange}
              className="form-control"
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              required
            >
              <MenuItem value="CHEP-Virtual">CHEP (Virtual)</MenuItem>
              <MenuItem value="Harbor LC-North">Harbor LC-North</MenuItem>
              <MenuItem value="Harbor LC-South">Harbor LC-South</MenuItem>
              <MenuItem value="La Habra">La Habra</MenuItem>
              <MenuItem value="PCHS">PCHS</MenuItem>
              <MenuItem value="Sky View">Sky View</MenuItem>
              <MenuItem value="Sunburst">Sunburst</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="form-group mb-3">
          <label className="d-block fw-bold mb-2">
            Student's Name (First and Last Initial) - for group sessions of more
            than one student, separate the names with a comma:
          </label>
          <TextField
            label="Student Name"
            name="presentStudent"
            size="small"
            value={formData.presentStudent}
            onChange={handleInputChange}
            className="form-control"
            required
          />
        </div>
        <div className="form-group mb-3">
          <label className="fw-bold mb-1">Session Date:</label>
          <input
            type="date"
            name="sessionDate"
            value={formData.sessionDate}
            onChange={handleInputChange}
            className="form-control"
            required
          />
        </div>

        <div className="form-group mb-3">
          <label className="fw-bold mb-1">Session Start Time:</label>
          <input
            type="time"
            name="sessionStartTime"
            value={formData.sessionStartTime}
            onChange={handleInputChange}
            className="form-control"
            required
          />
        </div>
        <div className="form-group mb-3">
          <label className="fw-bold mb-1">Session End Time:</label>
          <input
            type="time"
            name="sessionEndTime"
            value={formData.sessionEndTime}
            onChange={handleInputChange}
            className="form-control"
            required
          />
        </div>
        <div className="form-group mb-3">
          <label className="fw-bold mb-2">Select Grade Level</label>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel className="block">Grade Level</InputLabel>
            <Select
              size="small"
              name="grades"
              value={formData.grades}
              input={<OutlinedInput label="grade Level" />}
              onChange={handleInputChange}
              className="form-control"
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              required
            >
              {filteredGrades.map((val, index) => (
                <MenuItem key={index} value={val.value}>
                  {val.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="form-group mb-3">
          <label className="fw-bold mb-2">
            Name of student's core teacher (last name) - if there are multiple,
            please list all and separate them by a comma.
          </label>

          <TextField
            label="Teacher Name"
            name="teacherName"
            size="small"
            value={formData.teacherName}
            onChange={handleInputChange}
            className="form-control"
            required
          />
        </div>

        <div className="form-group mb-3">
          <label className="fw-bold mb-2">
            Please provide a brief description or the title of the
            activity/topic/skill that was covered?
          </label>

          <TextField
            label="Content File Name / Activity / Topic"
            name="contentFileName"
            size="small"
            value={formData.contentFileName}
            onChange={handleInputChange}
            className="form-control"
            required
          />
        </div>
        <div className="form-group mb-3">
          <label className="d-block fw-bold mb-1">Student's Disposition:</label>
          <FormControl component="fieldset">
            <RadioGroup
              name="disposition"
              value={formData.disposition}
              onChange={handleInputChange}
              required
            >
              <FormControlLabel
                value="Responsive and engaged"
                control={<Radio />}
                label="Responsive and engaged"
              />
              <FormControlLabel
                value="Passive and non-participatory"
                control={<Radio />}
                label="Passive and non-participatory"
              />
              <FormControlLabel
                value="Serious behavior concerns"
                control={<Radio />}
                label="Serious behavior concerns"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div className="form-group mb-3">
          <label className="fw-bold mb-1">
            Next Session Date-if applicable: (Please click on the calendar to
            input the date, to avoid typos.)
          </label>
          <input
            type="date"
            name="nextSessionDate"
            value={formData.nextSessionDate}
            onChange={handleInputChange}
            className="form-control"
            required
          />
        </div>
        <div className="text-center my-3">
          <Button
            size="medium"
            variant="contained"
            color="primary"
            type="submit"
          >
            Submit
          </Button>
        </div>
      </form>
      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
}
