

import React from 'react'
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { BACKEND_URI } from "../../config/config"
import logo from "../../Assets/logo.png"
Font.register({
    family: 'Times New Roman',
    fontStyle: 'bold',
    fontWeight: 'bold',
    src: 'https://fonts.cdnfonts.com/css/times-new-roman', // Replace with the actual path to your font file
});
const styles = StyleSheet.create({
    body: {
        display: "flex",
        alignItems: "center",
        padding: 12
    },
    table: {
        display: "table",
        width: "100%",
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        marginTop: 5,
    },
    table1: {
        display: "table",
        width: "100%",
        borderWidth: 1,
        marginTop: 20,
        borderRightWidth: 0,
        borderBottomWidth: 0,
    },
    weektable: {
        display: "table",
        width: "100%",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    tableRow1: {
        margin: "auto",
        flexDirection: "row"
    },
    tableRowmap: {
        display: "flex",
        flexDirection: "row",
        alignContent: "center"
    },
    tableColl2: {
        width: "40%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        padding: 10,
        fontSize: 11
    },
    tableCell1: {
        width: "60%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        padding: 10,
        fontSize: 11
    },
    tableCell3: {

        width: "100%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        padding: 10,
        fontSize: 11
    },
    tableCol1: {
        width: "20%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        padding: 10,
        fontSize: 11
    },
    tableCol2: {
        width: "45%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        padding: 10,
        fontSize: 11
    },
    tableCol3: {
        width: "15%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        padding: 10,
        fontSize: 11
    },
    tableWeekCol1: {
        width: "20%",
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderTopWidth: 0,
        padding: 10,
        fontSize: 11
    },
    tableWeekCol2: {
        width: "45%",
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderTopWidth: 0,
        padding: 10,
        fontSize: 11
    },
    tableWeekCol3: {
        width: "15%",
        borderStyle: "solid",
        borderWidth: 1,
        // borderLeftWidth: 0,
        borderTopWidth: 0,
        padding: 10,
        fontSize: 11
    },
    month: {
        fontWeight: 600,
        width: "95%",
        fontSize: 11,
        marginTop: 12,
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "flex-end"

    },
    tableCell: {
        margin: "auto",
        marginTop: 5,
        fontSize: 10
    },
    los: {
        display: "flex",
        alignItems: "center",
        marginBottom: 1,
        flexDirection: "row"
    },
    ImageWIdth: {
        width: "20%",
        marginTop: "10px",
        marginLeft: "22px",
        display: "flex",
        alignItems: "center",
    },
    textsWidth: {
        width: "70%",
        display: "flex",
        justifyContent: "center",
        marginLeft: '10%',
    },
    nameStudent: {
        backgroundColor: "black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        fontWeight: 'bold',
        width: "100%",
        borderLeftWidth: 0,
        borderTopWidth: 0,
        padding: 10,
        fontSize: 12
    },


});
// src = 
function ProgressCustomPDF({ data }) {
    console.log("--- data ==>", data.departments)
    return (
        <Document>
            <Page size="A4" style={styles.body}>
                <View style={styles.los}>
                    <View style={styles.ImageWIdth}>
                        {!data?.image ? (<Image src={logo} style={{ width: "100px" }} />)
                            : (<Image src={`${BACKEND_URI}/images/${data?.image}`} style={{ width: "100px" }} />)}

                    </View>
                    <View style={styles.textsWidth}>
                        <Text>
                            {data.program} Program
                        </Text>
                        <Text>
                            Weekly/Monthly Progress Report
                        </Text>
                    </View>
                </View>
                <View style={styles.month}>
                    <Text style={{ textAlign: "end" }}> For the month of: {data.date}</Text>

                </View>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCell1}>
                            <Text >Name of Student:   {data?.student_Name} </Text>
                        </View>
                        <View style={styles.tableColl2}>
                            <Text >Tutoring Start Date:   {data?.tutoring_Start_Date} </Text>
                        </View>
                    </View>

                    <View style={styles.tableRow}>
                        <View style={styles.tableCell1}>
                            <Text>Tutoring Agency:   Student Nest</Text>
                        </View>
                        <View style={styles.tableColl2}>
                            <Text>Referring Department: {data.departments} </Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCell1}>
                            <Text style={{ paddingBottom: "6px", fontWeight: 700 }}>Tutor’s Name:   {data.tutor_Name}</Text>

                        </View>
                        <View style={styles.tableColl2}>
                            <Text style={{ paddingBottom: "6px" }}>Telephone Number: {data.telephone_Number}</Text>
                        </View>
                    </View>
                </View>
                <View style={styles.table1}>
                    <View style={styles.tableRow1}>
                        <View style={styles.nameStudent}>
                            <Text>Week 1</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol1}>
                            <Text>Goal/Objectives</Text>
                        </View>
                        <View style={styles.tableCol2}>
                            <Text>Description of Objectives to Meet </Text>
                        </View>
                        <View style={styles.tableCol1}>
                            <Text>Session Date</Text>

                        </View>
                        <View style={styles.tableCol3}>
                            <Text>Goals Met</Text>
                        </View>
                    </View>
                </View>

                {data?.Task_One?.map((item, index) => {

                    return (<>
                        {item?.Goal === "" ? (<></>) : (

                            <View style={styles.tableRowmap}>
                                <View style={styles.tableWeekCol1}>
                                    <Text>{item?.Goal}</Text>
                                </View>
                                <View style={styles.tableWeekCol2}>
                                    <Text>{item.description}</Text>
                                </View>
                                <View style={styles.tableWeekCol1}>
                                    <Text>{item.Date}</Text>
                                </View>
                                <View style={styles.tableWeekCol3}>
                                    <Text>{item.group1}</Text>
                                </View>
                            </View>
                        )
                        }



                    </>)

                })}

                <View style={styles.table1}>
                    <View style={styles.tableRow1}>
                        <View style={styles.nameStudent}>
                            <Text>Week 2</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol1}>
                            <Text>Goal/Objectives</Text>
                        </View>
                        <View style={styles.tableCol2}>
                            <Text>Description of Objectives to Meet </Text>
                        </View>
                        <View style={styles.tableCol1}>
                            <Text>Session Date</Text>

                        </View>
                        <View style={styles.tableCol3}>
                            <Text>Goals Met</Text>
                        </View>
                    </View>
                </View>


                {data?.Task_Two?.map((item) => {
                    return (<>
                        {item?.Goal === "" ? (<></>) : (

                            <View style={styles.tableRowmap}>
                                <View style={styles.tableWeekCol1}>
                                    <Text>{item?.Goal}</Text>
                                </View>
                                <View style={styles.tableWeekCol2}>
                                    <Text>{item.description}</Text>
                                </View>
                                <View style={styles.tableWeekCol1}>
                                    <Text>{item.Date}</Text>
                                </View>
                                <View style={styles.tableWeekCol3}>
                                    <Text>{item.group1}</Text>
                                </View>
                            </View>
                        )
                        }



                    </>)

                })}

                <View style={styles.table1}>
                    <View style={styles.tableRow1}>
                        <View style={styles.nameStudent}>
                            <Text>Week 3</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol1}>
                            <Text>Goal/Objectives</Text>
                        </View>
                        <View style={styles.tableCol2}>
                            <Text>Description of Objectives to Meet </Text>
                        </View>
                        <View style={styles.tableCol1}>
                            <Text>Session Date</Text>

                        </View>
                        <View style={styles.tableCol3}>
                            <Text>Goals Met</Text>
                        </View>
                    </View>
                </View>
                {data?.Task_Three?.map((item) => {
                    return (<>
                        {item?.Goal === "" ? (<></>) : (

                            <View style={styles.tableRowmap}>
                                <View style={styles.tableWeekCol1}>
                                    <Text>{item?.Goal}</Text>
                                </View>
                                <View style={styles.tableWeekCol2}>
                                    <Text>{item.description}</Text>
                                </View>
                                <View style={styles.tableWeekCol1}>
                                    <Text>{item.Date}</Text>
                                </View>
                                <View style={styles.tableWeekCol3}>
                                    <Text>{item.group1}</Text>
                                </View>
                            </View>
                        )
                        }



                    </>)

                })}

                <View style={styles.table1}>
                    <View style={styles.tableRow1}>
                        <View style={styles.nameStudent}>
                            <Text>Week 4</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol1}>
                            <Text>Goal/Objectives</Text>
                        </View>
                        <View style={styles.tableCol2}>
                            <Text>Description of Objectives to Meet </Text>
                        </View>
                        <View style={styles.tableCol1}>
                            <Text>Session Date</Text>

                        </View>
                        <View style={styles.tableCol3}>
                            <Text>Goals Met</Text>
                        </View>
                    </View>
                </View>

                {data?.Task_Four?.map((item) => {
                    return (<>
                        {item?.Goal === "" ? (<></>) : (

                            <View style={styles.tableRowmap}>
                                <View style={styles.tableWeekCol1}>
                                    <Text>{item?.Goal}</Text>
                                </View>
                                <View style={styles.tableWeekCol2}>
                                    <Text>{item.description}</Text>
                                </View>
                                <View style={styles.tableWeekCol1}>
                                    <Text>{item.Date}</Text>
                                </View>
                                <View style={styles.tableWeekCol3}>
                                    <Text>{item.group1}</Text>
                                </View>
                            </View>
                        )
                        }



                    </>)

                })}
                <View style={styles.table1}>
                    <View style={styles.tableRow1}>
                        <View style={styles.nameStudent}>
                            <Text>Week 5</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol1}>
                            <Text>Goal/Objectives</Text>
                        </View>
                        <View style={styles.tableCol2}>
                            <Text>Description of Objectives to Meet </Text>
                        </View>
                        <View style={styles.tableCol1}>
                            <Text>Session Date</Text>

                        </View>
                        <View style={styles.tableCol3}>
                            <Text>Goals Met</Text>
                        </View>
                    </View>
                </View>

                {data?.Task_Five?.map((item) => {
                    return (<>
                        {item?.Goal === "" ? (<></>) : (

                            <View style={styles.tableRowmap}>
                                <View style={styles.tableWeekCol1}>
                                    <Text>{item?.Goal}</Text>
                                </View>
                                <View style={styles.tableWeekCol2}>
                                    <Text>{item.description}</Text>
                                </View>
                                <View style={styles.tableWeekCol1}>
                                    <Text>{item.Date}</Text>
                                </View>
                                <View style={styles.tableWeekCol3}>
                                    <Text>{item.group1}</Text>
                                </View>
                            </View>
                        )
                        }



                    </>)

                })}

            </Page>
        </Document >
    )
}

export default ProgressCustomPDF

























// import React from 'react'
// import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
// import { BACKEND_URI } from "../../config/config"
// import logo from "../../Assets/logo.png"
// Font.register({
//     family: 'Times New Roman',
//     fontStyle: 'bold',
//     fontWeight: 'bold',
//     src: 'https://fonts.cdnfonts.com/css/times-new-roman', // Replace with the actual path to your font file
// });
// const styles = StyleSheet.create({
//     body: {
//         display: "flex",
//         alignItems: "center"
//     },
//     table: {
//         display: "table",
//         width: "95%",
//         borderStyle: "solid",
//         borderWidth: 1,
//         borderRightWidth: 0,
//         borderBottomWidth: 0,
//         marginTop: 5,
//     },
//     table1: {
//         display: "table",
//         width: "95%",
//         borderWidth: 1,
//         marginTop: 20,
//         borderRightWidth: 0,
//         borderBottomWidth: 0,
//     },
//     tableRow: {
//         margin: "auto",
//         flexDirection: "row"
//     },
//     tableRow1: {
//         margin: "auto",
//         flexDirection: "row"
//     },
//     tableRowmap: {
//         margin: "auto",
//         display: "flex",
//         flexDirection: "row"
//     },
//     tableCol2: {
//         width: "30%",
//         borderStyle: "solid",
//         borderWidth: 1,
//         borderLeftWidth: 0,
//         borderTopWidth: 0,
//         padding: 10,
//         fontSize: 11
//     },
//     tableCell1: {
//         width: "70%",
//         borderStyle: "solid",
//         borderWidth: 1,
//         borderLeftWidth: 0,
//         borderTopWidth: 0,
//         padding: 10,
//         fontSize: 11
//     },
//     tableCell3: {
//         width: "100%",
//         borderStyle: "solid",
//         borderWidth: 1,
//         borderLeftWidth: 0,
//         borderTopWidth: 0,
//         padding: 10,
//         fontSize: 11
//     },
//     tableCol1: {
//         width: "20%",
//         borderStyle: "solid",
//         borderWidth: 1,
//         borderLeftWidth: 0,
//         borderTopWidth: 0,
//         padding: 10,
//         fontSize: 11
//     },
//     tableCol2: {
//         width: "60%",
//         borderStyle: "solid",
//         borderWidth: 1,
//         borderLeftWidth: 0,
//         borderTopWidth: 0,
//         padding: 10,
//         fontSize: 11
//     },
//     tableColWeek3: {
//         width: "20%",
//         borderStyle: "solid",
//         borderWidth: 1,
//         borderLeftWidth: 0,
//         borderTopWidth: 0,
//         padding: 10,
//         fontSize: 11
//     },
//     month: {
//         fontWeight: 600,
//         width: "95%",
//         fontSize: 11,
//         marginTop: 12,
//         display: "flex",
//         alignItems: "flex-end",
//         justifyContent: "flex-end"

//     },
//     tableCell: {
//         margin: "auto",
//         marginTop: 5,
//         fontSize: 10
//     },
//     los: {
//         display: "flex",
//         alignItems: "center",
//         marginBottom: 1,
//         flexDirection: "row"
//     },
//     ImageWIdth: {
//         width: "20%",
//         marginTop: "10px",
//         marginLeft: "22px",
//         display: "flex",
//         alignItems: "center",
//     },
//     textsWidth: {
//         width: "70%",
//         display: "flex",
//         justifyContent: "center",
//         marginLeft: '10%',
//     },
//     nameStudent: {
//         fontSize: 11,
//         fontWeight: 700
//     }

// });
// // src =
// function ProgressCustomPDF({ data }) {

//     return (
//         <Document>
//             <Page size="A4" style={styles.body}>
//                 <View style={styles.los}>
//                     <View style={styles.ImageWIdth}>
//                         {!data?.image ? (<Image src={logo} style={{ width: "100px" }} />)
//                             : (<Image src={`${BACKEND_URI}/images/${data?.image}`} style={{ width: "100px" }} />)}

//                     </View>
//                     <View style={styles.textsWidth}>
//                         <Text>
//                             {data.program} Program
//                         </Text>
//                         <Text>
//                             Weekly/Monthly Progress Report
//                         </Text>
//                     </View>
//                 </View>
//                 <View style={styles.month}>
//                     <Text style={{ textAlign: "end" }}>
//                         For the month of: {data.date}
//                     </Text>
//                 </View>
//                 <View style={styles.table}>
//                     <View style={styles.tableRow}>
//                         <View style={styles.tableCell1}>
//                             <Text style={{ paddingBottom: "12px" }}>Name of Student: </Text>
//                             <Text >{data?.student_Name} </Text>
//                         </View>
//                         <View style={styles.tableCol2}>
//                             <Text style={{ paddingBottom: "12px" }}>Tutoring Start Date </Text>
//                             <Text>{data?.tutoring_Start_Date}</Text>
//                         </View>
//                     </View>

//                     <View style={styles.tableRow}>
//                         <View style={styles.tableCell3}>
//                             <Text>Tutoring Agency:                Student Nest</Text>
//                         </View>
//                     </View>
//                     <View style={styles.tableRow}>
//                         <View style={styles.tableCell1}>
//                             <Text style={{ paddingBottom: "12px", fontWeight: 700 }}>Tutor’s Name: </Text>
//                             <Text>{data.tutor_Name}</Text>
//                         </View>
//                         <View style={styles.tableCol2}>
//                             <Text style={{ paddingBottom: "12px" }}>Telephone Number</Text>
//                             <Text>{data.telephone_Number}</Text>
//                         </View>
//                     </View>
//                 </View>


//                 {data?.Task_One?.map((item, index) => {
//                     return (<>
//                         {item?.Goal === "" ? (<></>) : (
//                             <View style={styles.table1}>
//                                 <View style={styles.tableRow1}>
//                                     <View style={styles.tableCell3}>
//                                         <Text>Week 1</Text>
//                                     </View>
//                                 </View>
//                                 <View style={styles.tableRow}>
//                                     <View style={styles.tableCol1}>
//                                         <Text>Goal/Obj.</Text>
//                                     </View>
//                                     <View style={styles.tableCol2}>
//                                         <Text >Description of Objective to Meet </Text>
//                                     </View>
//                                     <View style={styles.tableColWeek3}>
//                                         <Text>Objective Met</Text>
//                                     </View>
//                                 </View>

//                                 <View style={styles.tableRowmap} >
//                                     <View style={styles.tableCol1}>
//                                         <Text>{item?.Goal}</Text>
//                                     </View>
//                                     <View style={styles.tableCol2}>
//                                         <Text>{item.description}</Text>
//                                     </View>
//                                     <View style={styles.tableColWeek3}>
//                                         <Text>{item.group1}</Text>
//                                     </View>
//                                 </View>
//                             </View>)
//                         }



//                     </>)

//                 })}



//                 {data?.Task_Two?.map((item) => {
//                     return (<>
//                         {item?.Goal === "" ? (<></>) : (<View style={styles.table1}>
//                             <View style={styles.tableRow1}>
//                                 <View style={styles.tableCell3}>
//                                     <Text>Week 2</Text>
//                                 </View>
//                             </View>
//                             <View style={styles.tableRow}>
//                                 <View style={styles.tableCol1}>
//                                     <Text>Goal/Obj.</Text>
//                                 </View>
//                                 <View style={styles.tableCol2}>
//                                     <Text >Description of Objective to Meet </Text>
//                                 </View>
//                                 <View style={styles.tableColWeek3}>
//                                     <Text>Objective Met</Text>
//                                 </View>
//                             </View>

//                             <View style={styles.tableRowmap} >
//                                 <View style={styles.tableCol1}>
//                                     <Text>{item?.Goal}</Text>
//                                 </View>
//                                 <View style={styles.tableCol2}>
//                                     <Text>{item.description}</Text>
//                                 </View>
//                                 <View style={styles.tableColWeek3}>
//                                     <Text>{item.group1}</Text>
//                                 </View>
//                             </View>
//                         </View>)}

//                     </>)

//                 })}


//                 {data?.Task_Three?.map((item) => {
//                     return (<>
//                         {item?.Goal === "" ? (<></>) : (<View style={styles.table1}>
//                             <View style={styles.tableRow1}>
//                                 <View style={styles.tableCell3}>
//                                     <Text>Week 3</Text>
//                                 </View>
//                             </View>
//                             <View style={styles.tableRow}>
//                                 <View style={styles.tableCol1}>
//                                     <Text>Goal/Obj.</Text>
//                                 </View>
//                                 <View style={styles.tableCol2}>
//                                     <Text >Description of Objective to Meet </Text>
//                                 </View>
//                                 <View style={styles.tableColWeek3}>
//                                     <Text>Objective Met</Text>
//                                 </View>
//                             </View>

//                             <View style={styles.tableRowmap} >
//                                 <View style={styles.tableCol1}>
//                                     <Text>{item?.Goal}</Text>
//                                 </View>
//                                 <View style={styles.tableCol2}>
//                                     <Text>{item.description}</Text>
//                                 </View>
//                                 <View style={styles.tableColWeek3}>
//                                     <Text>{item.group1}</Text>
//                                 </View>
//                             </View></View>)}



//                     </>)

//                 })}



//                 {data?.Task_Four?.map((item) => {
//                     return (<>
//                         {item?.Goal === "" ? (<></>) : (< View style={styles.table1}>
//                             <View style={styles.tableRow1}>
//                                 <View style={styles.tableCell3}>
//                                     <Text>Week 4</Text>
//                                 </View>
//                             </View>
//                             <View style={styles.tableRow}>
//                                 <View style={styles.tableCol1}>
//                                     <Text>Goal/Obj.</Text>
//                                 </View>
//                                 <View style={styles.tableCol2}>
//                                     <Text >Description of Objective to Meet </Text>
//                                 </View>
//                                 <View style={styles.tableColWeek3}>
//                                     <Text>Objective Met</Text>
//                                 </View>
//                             </View>

//                             <View style={styles.tableRowmap} >
//                                 <View style={styles.tableCol1}>
//                                     <Text>{item?.Goal}</Text>
//                                 </View>
//                                 <View style={styles.tableCol2}>
//                                     <Text>{item.description}</Text>
//                                 </View>
//                                 <View style={styles.tableColWeek3}>
//                                     <Text>{item.group1}</Text>
//                                 </View>
//                             </View></View>)}



//                     </>)

//                 })}


//                 {data?.Task_Five?.map((item) => {
//                     return (<>
//                         {item?.Goal === "" ? (<></>) : (< View style={styles.table1}>
//                             <View style={styles.tableRow1}>
//                                 <View style={styles.tableCell3}>
//                                     <Text>Week 5</Text>
//                                 </View>
//                             </View>
//                             <View style={styles.tableRow}>
//                                 <View style={styles.tableCol1}>
//                                     <Text>Goal/Obj.</Text>

//                                 </View>
//                                 <View style={styles.tableCol2}>
//                                     <Text >Description of Objective to Meet </Text>
//                                 </View>
//                                 <View style={styles.tableColWeek3}>
//                                     <Text>Objective Met</Text>
//                                 </View>
//                             </View>

//                             <View style={styles.tableRowmap} >
//                                 <View style={styles.tableCol1}>
//                                     <Text>{item?.Goal}</Text>
//                                 </View>
//                                 <View style={styles.tableCol2}>
//                                     <Text>{item.description}</Text>
//                                 </View>
//                                 <View style={styles.tableColWeek3}>
//                                     <Text>{item.group1}</Text>
//                                 </View>
//                             </View></View>)}



//                     </>)

//                 })}

//             </Page>
//         </Document >
//     )
// }

// export default ProgressCustomPDF