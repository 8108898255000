import React, { useState } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
  RadioGroup,
  Radio,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Button,
  TextField,
} from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import { FormsData, GradeArray, SubjectData } from "../formData";
import axios from "axios";
import { BACKEND_URI } from "../../../config/config";

export default function LacoeGainForm({ program }) {
  const [formData, setFormData] = useState(FormsData);
  const [subjects, setSubjects] = useState(SubjectData);
  const allSubjects = Object.keys(subjects).slice(0, -1);
  console.log("selected Program", program);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value, // handle checkboxes and other inputs
    }));
  };
  let userData = JSON.parse(localStorage.getItem("studentNest"));
  const { firstName, lastName, address, email, mobileNumber } = userData;
  const token = localStorage.getItem("jwtToken");
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  const handleSubjectChange = (event) => {
    const { name, value, checked, type } = event.target;
    setSubjects((prevSubjects) => ({
      ...prevSubjects,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    const body = {
      program,
      name: `${firstName} ${lastName}`,
      email,
      contact: mobileNumber,
      address,
      formData,
      subjects,
    };
    // console.log("---bodyd====", body);
    const response = await axios.post(`${BACKEND_URI}/feedback`, body, {
      headers,
    });
    if (response) {
      toast.success("Form Submitted Succesfully");
      // const updatedStudentList = studentList.map(student => ({
      //     name: student.name,
      //     attendance: '',
      //     student: ''
      // }));
      setFormData(FormsData);
      setSubjects(SubjectData);
    }
  };
  return (
    <div className="container my-4">
      <h1 className="text-center mb-3">Tutoring Session Form</h1>
      <form
        onSubmit={handleSubmit}
        className="mw-50 mx-auto text-start"
        style={{ maxWidth: "550px" }}
      >
        <div className="form-group mb-3">
          <label className="d-block fw-bold mb-1">
            Was your session Virtual or In-Person?
          </label>
          <FormControl component="fieldset">
            <RadioGroup
              name="sessionType"
              value={formData.sessionType}
              onChange={handleInputChange}
              required
            >
              <FormControlLabel
                value="Virtual"
                control={<Radio />}
                label="Virtual"
              />
              <FormControlLabel
                value="In-Person"
                control={<Radio />}
                label="In-Person (including Hybrid)"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div className="form-group mb-3">
          <label className="fw-bold mb-1">Session Date:</label>
          <input
            type="date"
            name="sessionDate"
            value={formData.sessionDate}
            onChange={handleInputChange}
            className="form-control"
            required
          />
        </div>

        <div className="form-group mb-3">
          <label className="fw-bold mb-1">Session Start Time:</label>
          <input
            type="time"
            name="sessionStartTime"
            value={formData.sessionStartTime}
            onChange={handleInputChange}
            className="form-control"
            required
          />
        </div>

        <div className="form-group mb-3">
          <label className="fw-bold mb-1">Tutor Login Time:</label>
          <input
            type="time"
            name="tutorLoginTime"
            value={formData.tutorLoginTime}
            onChange={handleInputChange}
            className="form-control"
            required
          />
        </div>
        <div className="form-group mb-3">
          <label className="d-block fw-bold mb-2">Student Name</label>
          <TextField
            label="Student Name"
            name="studentName"
            size="small"
            value={formData.studentName}
            onChange={handleInputChange}
            className="form-control"
            required
          />
        </div>
        <div className="form-group mb-3">
          <label className="d-block fw-bold mb-1">
            Did the student log in?
          </label>
          <FormControl component="fieldset">
            <RadioGroup
              name="studentLoggedIn"
              value={formData.studentLoggedIn}
              onChange={handleInputChange}
              required
            >
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
              <FormControlLabel
                value="late"
                control={<Radio />}
                label="Yes,But Late"
              />
            </RadioGroup>
          </FormControl>
        </div>
        {(formData.studentLoggedIn === "yes" ||
          formData.studentLoggedIn === "late") && (
          <>
            <div className="form-group mb-3">
              <label className="fw-bold mb-1">Student Login Time:</label>
              <input
                type="time"
                name="studentLoginTime"
                value={formData.studentLoginTime}
                onChange={handleInputChange}
                className="form-control"
                required
              />
            </div>
            <div className="form-group mb-3">
              <label className="fw-bold mb-1">Session End Time:</label>
              <input
                type="time"
                name="sessionEndTime"
                value={formData.sessionEndTime}
                onChange={handleInputChange}
                className="form-control"
                required
              />
            </div>
            <div className="form-group mb-3">
              <label className="fw-bold mb-2">Select Grade Level</label>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel className="block text-sm">Grade Level</InputLabel>
                <Select
                  size="small"
                  name="grades"
                  value={formData.grades}
                  input={<OutlinedInput label="grade Level" />}
                  onChange={handleInputChange}
                  className="form-control"
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  required
                >
                  {GradeArray.map((val, index) => (
                    <MenuItem key={index} value={val.value}>
                      {val.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="form-group mb-3">
              <label className="fw-bold mb-1">Subjects Covered</label>

              <FormGroup>
                {allSubjects.map((subject, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        name={subject}
                        checked={subjects[subject]}
                        onChange={handleSubjectChange}
                        className="form-check"
                      />
                    }
                    label={subject}
                  />
                ))}
              </FormGroup>
              <TextField
                label="Other Subject"
                name="otherSubject"
                size="small"
                value={subjects.otherSubject}
                onChange={handleSubjectChange}
                className="form-control"
              />
            </div>

            <div className="form-group mb-3">
              <label className="fw-bold mb-2">
                Please provide the Content File Name (from the Content Library)
                or provide the name of the activity/topic/skill that was
                covered?
              </label>

              <TextField
                label="Content File Name / Activity / Topic"
                name="contentFileName"
                size="small"
                value={formData.contentFileName}
                onChange={handleInputChange}
                className="form-control"
                required
              />
            </div>

            <div className="form-group mb-3">
              <label className="d-block fw-bold mb-1">
                Student's Disposition:
              </label>
              <FormControl component="fieldset">
                <RadioGroup
                  name="disposition"
                  value={formData.disposition}
                  onChange={handleInputChange}
                  required
                >
                  <FormControlLabel
                    value="Responsive and engaged"
                    control={<Radio />}
                    label="Responsive and engaged"
                  />
                  <FormControlLabel
                    value="Passive and non-participatory"
                    control={<Radio />}
                    label="Passive and non-participatory"
                  />
                  <FormControlLabel
                    value="Serious behavior concerns"
                    control={<Radio />}
                    label="Serious behavior concerns"
                  />
                </RadioGroup>
              </FormControl>
            </div>

            <div className="form-group mb-3">
              <label className="d-block fw-bold mb-1">
                Did you confirm the next session (date/time) with the student?
              </label>
              <FormControl component="fieldset">
                <RadioGroup
                  name="nextSessionConfirmed"
                  value={formData.nextSessionConfirmed}
                  onChange={handleInputChange}
                  required
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </div>
            {formData.nextSessionConfirmed === "yes" && (
              <div className="form-group mb-3">
                <label className="fw-bold mb-1">Next Session Date:</label>
                <input
                  type="date"
                  name="nextSessionDate"
                  value={formData.nextSessionDate}
                  onChange={handleInputChange}
                  className="form-control"
                  required
                />
              </div>
            )}
          </>
        )}

        <div className="text-center my-3">
          <Button
            size="medium"
            variant="contained"
            color="primary"
            type="submit"
          >
            Submit
          </Button>
        </div>
      </form>
      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
}
