import React, { useState, useEffect } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
  RadioGroup,
  Radio,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Button,
  TextField,
} from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import { FormsData, GradeData, StudentData, SubjectData } from "../formData";
import axios from "axios";
import { BACKEND_URI } from "../../../config/config";
export default function CmsForm({ program }) {
  const [formData, setFormData] = useState(FormsData);
  const [gradeLevel, setGradeLevel] = useState(GradeData);
  const [subjects, setSubjects] = useState(SubjectData);
  const [studentList, setStudentList] = useState([
    { name: "", attendance: "", student: "" },
  ]);
  const [additionalStudents, setAdditionalStudents] = useState("");
  const allSubjects = Object.keys(subjects).slice(0, -1);
  // console.log('selected Program', program)
  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value, // handle checkboxes and other inputs
    }));
  };
  const handleSubjectChange = (event) => {
    const { name, value, checked, type } = event.target;
    setSubjects((prevSubjects) => ({
      ...prevSubjects,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const handleStudentChange = (index, field, value) => {
    const newStudentList = [...studentList];
    newStudentList[index][field] = value; // Update the specific field of the student
    setStudentList(newStudentList);
  };
  function handleGradeChange(event) {
    const { name, checked } = event.target;
    const newGradeLevel = { ...gradeLevel, [name]: checked }; // updates the grade level
    setGradeLevel(newGradeLevel);
  }
  let userData = JSON.parse(localStorage.getItem("studentNest"));
  const { firstName, lastName, address, email, mobileNumber } = userData;
  const token = localStorage.getItem("jwtToken");
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const grades = Object.keys(gradeLevel).filter((grade) => gradeLevel[grade]);
    const body = {
      program,
      name: `${firstName} ${lastName}`,
      email,
      contact: mobileNumber,
      address,
      formData,
      subjects,
      gradeLevel: grades,
      studentList,
    };
    const response = await axios.post(`${BACKEND_URI}/feedback`, body, {
      headers,
    });
    if (response) {
      toast.success("Form Submitted Succesfully");
      const updatedStudentList = studentList.map((student) => ({
        name: student.name,
        attendance: "",
        student: "",
      }));
      setFormData(FormsData);
      setGradeLevel(GradeData);
      setStudentList(updatedStudentList);
      setSubjects(SubjectData);
    }
  };
  useEffect(() => {
    const ratio = formData.studentGroupSize.split(":")[1];
    const numberOfStudents = ratio === "6+" ? 6 : parseInt(ratio, 10);

    const newStudentList = Array.from(
      { length: numberOfStudents },
      (_, index) => ({
        name: `Student ${index + 1}`,
        attendance: "",
        student: "",
      })
    );
    setStudentList(newStudentList);
  }, [formData.studentGroupSize]);
  return (
    <div className="container my-4">
      <h1 className="text-center mb-3">Tutoring Session Form</h1>
      <form
        onSubmit={handleSubmit}
        className="mw-50 mx-auto text-start"
        style={{ maxWidth: "550px" }}
      >
        <div className="form-group mb-3">
          <label className="d-block fw-bold mb-1">CMS Tutoring Ratio</label>
          <FormControl component="fieldset">
            <RadioGroup
              name="studentGroupSize"
              value={formData.studentGroupSize}
              onChange={handleInputChange}
              required
            >
              <FormControlLabel value="1:1" control={<Radio />} label="1:1" />
              <FormControlLabel value="1:2" control={<Radio />} label="1:2" />
              <FormControlLabel value="1:3" control={<Radio />} label="1:3" />
              <FormControlLabel value="1:4" control={<Radio />} label="1:4" />
              <FormControlLabel value="1:5" control={<Radio />} label="1:5" />
              <FormControlLabel value="1:6+" control={<Radio />} label="1:6+" />
            </RadioGroup>
          </FormControl>
        </div>
        <div className="form-group mb-3">
          <label className="d-block fw-bold">
            Check the box for whether the student is present or absent AND then
            type the name of the student in the "Other" field.
          </label>
          {studentList.map((student, index) => (
            <div className="form-group m-3" key={index}>
              <label className="fw-bold mb-1 d-block">{student.name}</label>
              <FormControl component="fieldset">
                <RadioGroup
                  name={`attendance-${index}`} // unique name for each student
                  value={student.attendance}
                  onChange={(e) =>
                    handleStudentChange(index, "attendance", e.target.value)
                  }
                  required
                >
                  <FormControlLabel
                    value="Present"
                    control={<Radio />}
                    label="Present"
                  />
                  <FormControlLabel
                    value="Absent"
                    control={<Radio />}
                    label="Absent"
                  />
                </RadioGroup>
              </FormControl>
              <TextField
                label="Other"
                name={`student-${index}`}
                size="small"
                value={student.student}
                onChange={(e) =>
                  handleStudentChange(index, "student", e.target.value)
                }
                className="form-control"
                required
              />
            </div>
          ))}
          {formData.studentGroupSize === "1:6+" && (
            <div className="form-group m-3">
              <label className="fw-bold d-block mb-1">
                If you had more than 6 students in the session, type their names
                here separated by commas:
              </label>
              <TextField
                label="Additional Students"
                size="small"
                name="additionalStudent"
                value={formData.additionalStudent}
                onChange={handleInputChange}
                className="form-control"
                multiline
                rows={2}
              />
            </div>
          )}
        </div>
        <div className="form-group mb-3">
          <label className="d-block fw-bold mb-1">
            Was your session Virtual or In-Person?
          </label>
          <FormControl component="fieldset">
            <RadioGroup
              name="sessionType"
              value={formData.sessionType}
              onChange={handleInputChange}
              required
            >
              <FormControlLabel
                value="Virtual"
                control={<Radio />}
                label="Virtual"
              />
              <FormControlLabel
                value="In-Person"
                control={<Radio />}
                label="In-Person (including Hybrid)"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div className="form-group mb-3">
          <label className="fw-bold mb-2">Select School</label>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel className="block">School Name</InputLabel>
            <Select
              size="small"
              name="schoolName"
              placeholder="School Name"
              value={formData.schoolName}
              input={<OutlinedInput label="School Name" />}
              onChange={handleInputChange}
              className="form-control"
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              required
            >
              <MenuItem value="Crestdale Middle School">
                Crestdale Middle School
              </MenuItem>
              <MenuItem value="Garinger High School">
                Garinger High School
              </MenuItem>
              <MenuItem value="Harding University School">
                Harding University School
              </MenuItem>
              <MenuItem value="James Martin Middle School">
                James Martin Middle School
              </MenuItem>
              <MenuItem value="McClintock Middle School">
                McClintock Middle School
              </MenuItem>
              <MenuItem value="Pineville Elementary">
                Pineville Elementary
              </MenuItem>
              <MenuItem value="Pinewood Elementary">
                Pinewood Elementary
              </MenuItem>
              <MenuItem value="Winget Park Elementary">
                Winget Park Elementary
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="form-group mb-3">
          <label className="fw-bold mb-1">Session Date:</label>
          <input
            type="date"
            name="sessionDate"
            value={formData.sessionDate}
            onChange={handleInputChange}
            className="form-control"
            required
          />
        </div>

        <div className="form-group mb-3">
          <label className="fw-bold mb-1">Session Start Time:</label>
          <input
            type="time"
            name="sessionStartTime"
            value={formData.sessionStartTime}
            onChange={handleInputChange}
            className="form-control"
            required
          />
        </div>

        <div className="form-group mb-3">
          <label className="fw-bold mb-1">Session End Time:</label>
          <input
            type="time"
            name="sessionEndTime"
            value={formData.sessionEndTime}
            onChange={handleInputChange}
            className="form-control"
            required
          />
        </div>

        <div className="form-group mb-3">
          <label className="fw-bold mb-1">Tutor Login Time:</label>
          <input
            type="time"
            name="tutorLoginTime"
            value={formData.tutorLoginTime}
            onChange={handleInputChange}
            className="form-control"
            required
          />
        </div>
        {/* <div className="form-group mb-3">
                    <label className='d-block fw-bold mb-1'>Did the student log in?</label>
                    <FormControl component="fieldset">
                        <RadioGroup
                            name="studentLoggedIn"
                            value={formData.studentLoggedIn}
                            onChange={handleInputChange}
                            required
                        >
                            <FormControlLabel
                                value="yes"
                                control={<Radio />}
                                label="Yes"
                            />
                            <FormControlLabel
                                value="no"
                                control={<Radio />}
                                label="No"
                            />
                            <FormControlLabel
                                value="late"
                                control={<Radio />}
                                label="Yes,But Late"
                            />
                        </RadioGroup>
                    </FormControl>
                </div> */}
        <div className="form-group mb-3">
          <label className="fw-bold mb-1">
            Grade Level(s) of Student(s): (select multiple if applicable)
          </label>

          <FormGroup>
            {Object.keys(gradeLevel).map((value, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    name={value}
                    checked={gradeLevel[value]}
                    onChange={handleGradeChange}
                    className="form-check"
                  />
                }
                label={value}
              />
            ))}
          </FormGroup>
        </div>

        <div className="form-group mb-3">
          <label className="fw-bold mb-1">
            What is/are the subject(s) that you went over in this session?
          </label>

          <FormGroup>
            {allSubjects.map((subject, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    name={subject}
                    checked={subjects[subject]}
                    onChange={handleSubjectChange}
                    className="form-check"
                  />
                }
                label={subject}
              />
            ))}
          </FormGroup>
          <TextField
            label="Other Subject"
            name="otherSubject"
            size="small"
            value={subjects.otherSubject}
            onChange={handleSubjectChange}
            className="form-control"
          />
        </div>

        <div className="form-group mb-3">
          <label className="fw-bold mb-2">
            Please provide the Content File Name (from the Content Library) or
            provide the name of the activity/topic/skill that was covered?
          </label>

          <TextField
            label="Content File Name / Activity / Topic"
            name="contentFileName"
            size="small"
            value={formData.contentFileName}
            onChange={handleInputChange}
            className="form-control"
            required
          />
        </div>

        <div className="form-group mb-3">
          <label className="d-block fw-bold mb-1">Student's Disposition:</label>
          <FormControl component="fieldset">
            <RadioGroup
              name="disposition"
              value={formData.disposition}
              onChange={handleInputChange}
              required
            >
              <FormControlLabel
                value="Responsive and engaged"
                control={<Radio />}
                label="Responsive and engaged"
              />
              <FormControlLabel
                value="Passive and non-participatory"
                control={<Radio />}
                label="Passive and non-participatory"
              />
              <FormControlLabel
                value="Serious behavior concerns"
                control={<Radio />}
                label="Serious behavior concerns"
              />
            </RadioGroup>
          </FormControl>
        </div>

        <div className="form-group mb-3">
          <label className="d-block fw-bold mb-1">
            Did you confirm the next session (date/time) with the student?
          </label>
          <FormControl component="fieldset">
            <RadioGroup
              name="nextSessionConfirmed"
              value={formData.nextSessionConfirmed}
              onChange={handleInputChange}
              required
            >
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </div>
        {formData.nextSessionConfirmed === "yes" && (
          <div className="form-group mb-3">
            <label className="fw-bold mb-1">Next Session Date:</label>
            <input
              type="date"
              name="nextSessionDate"
              value={formData.nextSessionDate}
              onChange={handleInputChange}
              className="form-control"
              required
            />
          </div>
        )}

        <div className="text-center my-3">
          <Button
            size="medium"
            variant="contained"
            color="primary"
            type="submit"
          >
            Submit
          </Button>
        </div>
      </form>
      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
}
