import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from "react-router-dom"
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import toast, { Toaster } from 'react-hot-toast';
import { BACKEND_URI } from "../../config/config"

function UpdateRole() {
    const [active, setActive] = useState(false);
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [currentTime, setCurrentTime] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const params = useParams();
    const activeChange = (e) => {
        setActive(!active)
    }
    const token = localStorage.getItem("jwtToken")
    let student_data = JSON.parse(localStorage.getItem("studentNest"));
    const headers = {

        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"

    };
    const updateSingleRole = async () => {
        try {
            await axios.get(`${BACKEND_URI}/single_role_data/${params.id}`, { headers }).then((res1) => {
                setTitle(res1.data.title)
                setDescription(res1.data.description)
                setCurrentTime(res1.data.currentTime);

            })
        } catch (e) {

            if (e.response.status === 403) {
                navigate("/sidebar/dashboard")
            }
            console.log(e)
        }

    }
    const UpdateData = async () => {
        let First_Name = student_data.firstName
        let Last_Name = student_data.lastName
        let dateTime = new Date().toDateString()
        try {
            setLoading(true)
            await axios.put(`${BACKEND_URI}/update_role/${params.id}`, {
                active,
                title,
                description,
                currentTime,
            }, { headers }).then(async (res) => {
                if (res) {

                    let status = `${title} role has been Updated by ${First_Name} ${Last_Name}`
                    await axios.post(`${BACKEND_URI}/activity_Log`, {
                        First_Name,
                        Last_Name,
                        dateTime,
                        status
                    })
                }
                setLoading(false)
                if (res.data.active == "true") {
                    setActive(false)
                }

                navigate("/sidebar/role")
                setTimeout(() => { toast.success("Role Updated successfully") }, 10)

            })
        } catch (e) {
            if (e.response.status === 403) {
                navigate("/sidebar/dashboard")
            }
            setLoading(false)
            toast.error("May be Server Error! Please Refresh Page")
        }
    }
    useEffect(() => {
        updateSingleRole()
    }, [])
    return (
        <div className='container'>
            <div className='row user-box-1'>
                <div className='col-lg-12 col-12  d-flex justify-content-center  justify-content-between align-items-center pt-3 pb-3'>
                    <h4 className='user-h4 mt-2'>NEW ROLE</h4>
                </div>
            </div>
            <div className='row d-flex flex-column justify-content-center justify-content-between pt-3 pb-3 align-items-center' style={{ background: "#c7d7df", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
                <div className='col-lg-6 text-md-start mt-2'>
                    <div className="mb-3 d-flex align-items-center">
                        <div className='col-md-2'>
                            <label htmlFor="exam pleFormControlInput1" className="form-label mt-2">Title</label>
                        </div>
                        <input type="email" className="form-control ms-3" id="exampleFormControlInput1" placeholder="Role Name" value={title} onChange={(e) => setTitle(e.target.value)} required />
                        <br />
                    </div>
                </div>
                <div className='col-lg-6 text-md-start mt-2'>
                    <div className="mb-3 d-flex align-items-center">
                        <div className='col-md-2'>
                            <label htmlFor="exampleFormControlInput1" className="form-label mt-2">Description</label>
                        </div>
                        <input type="email" className="form-control ms-3" id="exampleFormControlInput1" placeholder="Description" value={description} onChange={(e) => setDescription(e.target.value)} required /><br />
                    </div>
                </div>
                <div className='col-lg-6 text-md-start mt-2 d-flex'>
                    <div className='col-md-2'>
                        <label htmlFor="exampleFormControlInput1" className="form-label mt-2" value={active} onChange={activeChange}>Status</label>
                    </div>
                    <label className="switch">
                        <input type="checkbox" id="togBtn" value={active} onChange={activeChange} />
                        <div className="slider round">
                            <span className="on" >Active</span>
                            <span className="off">Inactive</span>
                        </div>
                    </label>
                </div>
                <div className='col-md-11 mt-4 pt-3 pb-3 mb-5' style={{ borderBottom: "1px solid #838383", borderTop: "1px solid #838383" }}>
                    <button className='btn btn-save me-2' onClick={UpdateData}>{loading == true ? <Spinner animation="border" /> : <span>Update</span>}</button>
                    <button className='btn btn-Cancel' onClick={() => navigate("/sidebar/role")}>Cancel</button>
                </div>
            </div>
            <Toaster
                position="top-right"
                reverseOrder={false}
            />
        </div>
    )
}

export default UpdateRole