
// --------------------------------------------------------------------
// import React from 'react';
// import { convert24HoursTo12Hours } from '../../Convertor';

// const SchedulePopup = ({ data, onClose }) => {
//     // Grouping data by day
//     const groupedData = data.reduce((acc, curr) => {
//         if (!acc[curr.day]) {
//             acc[curr.day] = {
//                 students: [],
//                 startTimes: [],
//                 endTimes: []
//             };
//         }
//         acc[curr.day].students.push(curr.text);
//         acc[curr.day].startTimes.push(convert24HoursTo12Hours(curr.startTime));
//         acc[curr.day].endTimes.push(convert24HoursTo12Hours(curr.endTime));
//         return acc;
//     }, {});

//     const overlayStyle = {
//         position: 'fixed',
//         top: 0,
//         left: 0,
//         width: '100%',
//         height: '100%',
//         backgroundColor: 'rgba(0, 0, 0, 0.4)',
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//         zIndex: 1000,
//     };

//     const contentStyle = {
//         width: '500px',
//         maxWidth: '90%',
//         padding: '20px',
//         backgroundColor: 'white',
//         borderRadius: '10px',
//         boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
//         textAlign: 'center',
//     };
//     const buttonStyle = {
//         padding: '10px 20px',
//         backgroundColor: '#007bff',
//         color: 'white',
//         border: 'none',
//         borderRadius: '5px',
//         cursor: 'pointer',
//     };

//     return (
//         <div style={overlayStyle}>
//             <div style={contentStyle}>
//                 <h3 style={{ fontWeight: "600", textDecoration: 'underline' }}>Schedule Details</h3>
//                 <div style={{ overflowX: 'auto' }}>
//                     <table className='table'>
//                         <thead>
//                             <tr>
//                                 <th>Day</th>
//                                 <th>Student</th>
//                                 <th>Start Time</th>
//                                 <th>End Time</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {Object.keys(groupedData).map(day => (
//                                 <tr key={day}>
//                                     <td>{day}</td>
//                                     <td>{groupedData[day].students.join(', ')}</td>
//                                     <td>{groupedData[day].startTimes.join(', ')}</td>
//                                     <td>{groupedData[day].endTimes.join(', ')}</td>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                 </div>
//                 <button style={buttonStyle} onClick={onClose}>
//                     Close
//                 </button>
//             </div>
//         </div>
//     );
// }

// export default SchedulePopup;
//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
// import React from 'react';
// import { convert24HoursTo12Hours } from '../../Convertor';

// const SchedulePopup = ({ data, onClose }) => {
//     const overlayStyle = {
//         position: 'fixed',
//         top: 0,
//         left: 0,
//         width: '100%',
//         height: '100%',
//         backgroundColor: 'rgba(0, 0, 0, 0.4)',
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//         zIndex: 1000,
//     };

//     const contentStyle = {
//         width: '500px',
//         maxWidth: '90%',
//         padding: '20px',
//         backgroundColor: 'white',
//         borderRadius: '10px',
//         boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
//         textAlign: 'center',
//     };
//     const buttonStyle = {
//         padding: '10px 20px',
//         backgroundColor: '#007bff',
//         color: 'white',
//         border: 'none',
//         borderRadius: '5px',
//         cursor: 'pointer',
//     };

//     return (
//         <div style={overlayStyle}>
//             <div style={contentStyle}>
//                 <h3 style={{ fontWeight: "600", textDecoration: 'underline' }}>Schedule Details</h3>
//                 <div style={{ overflowX: 'auto' }}>
//                     <table className='table'>
//                         <thead>
//                             <tr>
//                                 <th>Day</th>
//                                 <th>Student</th>
//                                 <th>Start Time</th>
//                                 <th>End Time</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {Object.entries(data.reduce((acc, { day, text, startTime, endTime }) => {
//                                 acc[day] = acc[day] || { students: [], startTimes: [], endTimes: [] };
//                                 acc[day].students.push(text);
//                                 acc[day].startTimes.push(convert24HoursTo12Hours(startTime));
//                                 acc[day].endTimes.push(convert24HoursTo12Hours(endTime));
//                                 return acc;
//                             }, {})).map(([day, { students, startTimes, endTimes }]) => (
//                                 <React.Fragment key={day}>
//                                     <tr>
//                                         <td rowSpan={students.length}>{day}</td>
//                                         <td>{students[0]}</td>
//                                         <td>{startTimes[0]}</td>
//                                         <td>{endTimes[0]}</td>
//                                     </tr>
//                                     {students.slice(1).map((student, index) => (
//                                         <tr key={index}>
//                                             <td>{student}</td>
//                                             <td>{startTimes[index + 1]}</td>
//                                             <td>{endTimes[index + 1]}</td>
//                                         </tr>
//                                     ))}
//                                 </React.Fragment>
//                             ))}
//                         </tbody>
//                     </table>
//                 </div>
//                 <button style={buttonStyle} onClick={onClose}>
//                     Close
//                 </button>
//             </div>
//         </div>
//     );
// }

// export default SchedulePopup;

///////////////////////////////////////////////

import React from 'react';
import { convert24HoursTo12Hours } from '../../Convertor';

const SchedulePopup = ({ data, onClose, name }) => {
    const overlayStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    };

    const contentStyle = {
        width: '50%',
        padding: '20px',
        backgroundColor: 'white',
        borderRadius: '10px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
        textAlign: 'center',
    };
    const buttonStyle = {
        padding: '10px 20px',
        backgroundColor: '#007bff',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    };
    console.log("name ===", name)
    return (
        <div style={overlayStyle}>
            <div style={contentStyle}>
                <div style={{ marginBottom: '10px' }}>
                    <h3 style={{ fontWeight: "600", textDecoration: 'underline' }}>Schedule Details</h3>
                    <strong>Tutor : {name}</strong>
                </div>
                {data.length === 0 ? (
                    <h5 style={{ margin: '30px' }}>No records to display</h5>
                ) : (
                    <div style={{ overflowX: 'auto' }}>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>Day</th>
                                    <th>Student</th>
                                    <th>Start Time</th>
                                    <th>End Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(data.reduce((acc, { day, text, startTime, endTime }) => {
                                    acc[day] = acc[day] || { students: [], startTimes: [], endTimes: [] };
                                    acc[day].students.push(text);
                                    acc[day].startTimes.push(convert24HoursTo12Hours(startTime));
                                    acc[day].endTimes.push(convert24HoursTo12Hours(endTime));
                                    return acc;
                                }, {})).map(([day, { students, startTimes, endTimes }]) => (
                                    <React.Fragment key={day}>
                                        <tr>
                                            <td rowSpan={students.length}>{day}</td>
                                            <td>{students[0]}</td>
                                            <td>{startTimes[0]}</td>
                                            <td>{endTimes[0]}</td>
                                        </tr>
                                        {students.slice(1).map((student, index) => (
                                            <tr key={index}>
                                                <td>{student}</td>
                                                <td>{startTimes[index + 1]}</td>
                                                <td>{endTimes[index + 1]}</td>
                                            </tr>
                                        ))}
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
                <button style={buttonStyle} onClick={onClose}>
                    Close
                </button>
            </div>
        </div>
    );
}

export default SchedulePopup;
