// export const BACKEND_URI = "http://localhost:8000";

export const BACKEND_URI = "https://rktutoring.com:8000";


//  "homepage": "https://rktutoring.com",
// "start": "set SET NODE_OPTIONS=--openssl-legacy-provider && react-scripts start",
// "build": "set SET NODE_OPTIONS=--openssl-legacy-provider && react-scripts build",


//set GENERATE_SOURCEMAP=false && react-scripts build --openssl-legacy-provider && set SET NODE_OPTIONS=--max-old-space-size=4096