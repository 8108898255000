import React from 'react';
import { Link } from 'react-router-dom';
const NotFound = () => {
    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <p style={{ fontSize: '100px', fontStyle: 'italic' }}>Oops</p>
            <h1>404 - Page Not Found</h1>
            <p>The page you're looking for does not exist.</p>
            <Link to="/sidebar/dashboard" style={linkStyle}>Go to Homepage </Link>
        </div>
    );
};
const linkStyle = {
    display: 'inline-block',
    padding: '10px 20px',
    backgroundColor: 'dodgerblue',
    color: 'white',
    textDecoration: 'none',
    borderRadius: '5px',
};
export default NotFound;
