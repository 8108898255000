import React from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '55%',
    maxHeight: 'calc(110vh - 100px)',
    overflowY: 'auto',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function FeedbackModal({ data, open, handleClose }) {
    function timeConverter(time24) {
        const [hours, minutes] = time24.split(':');
        let suffix = 'AM';
        let hour = parseInt(hours, 10);
        if (hour >= 12) {
            suffix = 'PM';
            hour = hour === 12 ? 12 : hour - 12;
        }
        if (hour === 0) {
            hour = 12;
        }
        return `${hour}:${minutes} ${suffix}`;
    }

    function getTrueSubjects(subjects) {
        const trueSubjects = Object.entries(subjects)
            .filter(([subject, value]) => value === true && subject !== 'otherSubject')
            .map(([subject]) => subject);

        if (subjects.otherSubject.trim() !== "") {
            trueSubjects.push(subjects.otherSubject);
        }
        return trueSubjects;
    }
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <h2 className="text-center mb-3">Feedback data</h2>
                    <div className="mb-3">
                        <p><strong>UserName:</strong> {data.name}</p>
                        <p><strong>Program Name:</strong> {data.program}</p>
                        {data?.formData?.schoolName && <p><strong>School Name:</strong> {data.formData.schoolName}</p>}
                        {data?.formData?.sessionDate && <p><strong>Session Date:</strong> {data.formData.sessionDate}</p>}
                        {data?.formData?.sessionStartTime && <p><strong>Session StartTime:</strong> {timeConverter(data.formData.sessionStartTime)}</p>}
                        {data?.formData?.sessionEndTime && <p><strong>Session EndTime:</strong> {timeConverter(data.formData.sessionEndTime)}</p>}
                        {data?.formData?.tutorLoginTime && <p><strong>Tutor LoginTime:</strong> {timeConverter(data.formData.tutorLoginTime)}</p>}
                        {data?.formData?.studentLoginTime && <p><strong>Student LoginTime:</strong> {timeConverter(data.formData.studentLoginTime)}</p>}
                        {data?.formData?.studentGroupSize && <p><strong>Student Group Size:</strong> {data.formData.studentGroupSize}</p>}
                        {data?.formData?.sessionType && <p><strong>Session Type:</strong> {data.formData.sessionType}</p>}
                        {data?.formData?.studentName && <p><strong> Student Name:</strong> {data.formData.studentName}</p>}
                        {data?.formData?.period && <p><strong> Total Period:</strong> {data.formData.period}</p>}
                        {data?.formData?.blockPeriod && <p><strong> Was this Block Period:</strong> {data.formData.blockPeriod}</p>}
                        {data?.formData?.comments && <p><strong> Comments on student learning:</strong> {data.formData.comments}</p>}
                        {data?.formData?.activeStudent && <p><strong> Student activity during the specified instruction time:</strong> {data.formData.activeStudent}</p>}
                        {data?.formData?.grades && <p><strong> Student Grades:</strong> {data.formData.grades}</p>}
                        {data?.formData?.presentStudent && (
                            <div className="mb-3">
                                <p><strong>Present Student List</strong></p>
                                <div className="p-3 bg-light rounded">
                                    {data?.formData?.presentStudent?.split(',').map((value, index) => (
                                        <p key={index} className="mb-0"><strong>Student Name:</strong> {value}</p>
                                    ))}
                                </div>
                            </div>
                        )}

                        {data?.formData?.absentStudent && (
                            <div className="mb-3">
                                <p><strong>Absent Student List</strong></p>
                                <div className="p-3 bg-light rounded">
                                    {data?.formData?.absentStudent?.split(',').map((value, index) => (
                                        <p key={index} className="mb-0"><strong>Student Name:</strong> {value}</p>
                                    ))}
                                </div>
                            </div>
                        )}

                        {(data?.subjects !== 0 && data.program !== 'LAUSD' && data.program !== "OCDE") && (
                            <div className="mb-3">
                                <p><strong>Subjects</strong></p>
                                <div className="p-3 bg-light rounded">
                                    {getTrueSubjects(data.subjects).map((val, index) => (
                                        <p key={index} className="mb-1">{val}</p>
                                    ))}
                                </div>
                            </div>
                        )}

                        {data?.gradeLevel.length !== 0 && (
                            <div className="mb-3">
                                <p><strong>Grades</strong></p>
                                <div className="p-3 bg-light rounded">
                                    {data.gradeLevel.map((val, index) => (
                                        <p key={index} className="mb-1">{val}</p>
                                    ))}
                                </div>
                            </div>
                        )}

                        {data?.studentList.length !== 0 && (
                            <div className="mb-3">
                                <p><strong>Student List</strong></p>
                                <div className="p-3 bg-light rounded">
                                    {data.studentList.map((student, index) => (
                                        <div key={index} className="d-flex align-items-center mb-1">
                                            <p className="mb-0"><strong>Student Name:</strong> {student.student}</p>
                                            <p className="mb-0"><strong>Attendance:</strong> {student.attendance}</p>
                                        </div>
                                    ))}
                                    {data?.formData?.additionalStudent?.split(',').map((value, index) => (
                                        <p key={index} className="mb-0"><strong>Student Name:</strong> {value}</p>
                                    ))}
                                </div>
                            </div>
                        )}

                        {data?.formData?.contentFileName && <p><strong>Content FileName:</strong> {data.formData.contentFileName}</p>}
                        {data?.formData?.disposition && <p><strong>Disposition:</strong> {data.formData.disposition}</p>}
                        {data?.formData?.nextSessionConfirmed && <p><strong>Next Session Confirmed:</strong> {data.formData.nextSessionConfirmed}</p>}
                        {data?.formData?.nextSessionConfirmed === 'yes' && data.formData.nextSessionDate && (
                            <p><strong>Next Session Date:</strong> {data.formData.nextSessionDate}</p>
                        )}
                    </div>

                </Box>
            </Modal>
        </div>
    );
}
