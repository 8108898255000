import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { BACKEND_URI } from '../../config/config';
import DataTable, { createTheme } from 'react-data-table-component';
import { Link } from "react-router-dom";
import Placeholder from 'react-bootstrap/Placeholder';
import { useNavigate, } from "react-router-dom"
import Popup from '../Popup/Popup';
import ActivityDetailsPopup from './ActivityDetailsPopup';
function ActivityLog() {

  const [activityData, setActivityData] = useState([])
  const [activityLength, setActivityLength] = useState(0)
  const [search, setSearch] = useState("")
  const [filterSearch, setFilterSearch] = useState([])
  const [tableLoading, setTableLoading] = useState(false)
  const [sckelton, setSckelton] = useState(false);
  const navigate = useNavigate()
  const [popupMessage, setPopupMessage] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const handleAccessDenied = () => {
    setPopupMessage(true);
  };
  const closeErrorPopup = () => {
    setPopupMessage(false);
    navigate("/sidebar/dashboard")
  };
  const token = localStorage.getItem("jwtToken")
  const headers = {
    "Authorization": `Bearer ${token}`,
    "Content-Type": "application/json"
  };
  const activityGetData = async () => {
    try {
      setTableLoading(true)
      setSckelton(true)
      await axios.get(`${BACKEND_URI}/activity_Log`).then((res) => {
        setActivityData(res.data)
        setFilterSearch(res.data)
        setActivityLength(res.data.length)
        setTableLoading(false)
        setSckelton(false)
      })
    } catch (e) {
      if (e.response.status === 403) {
        handleAccessDenied()
      }
      console.log("e", e);
      setTableLoading(false)
      setSckelton(false)
    }
  }
  createTheme('solarized', {
    background: {
      default: '#c0dfdf',

    },
    text: {
      primary: '#08272a',
      secondary: '#08272a',
    },
  });

  const handleClick = (row) => {
    // Extract the data you want to display in the popup
    const { First_Name, Last_Name, status, dateTime } = row;

    // Create an object with the extracted data
    const rowData = {
      First_Name,
      Last_Name,
      status,
      dateTime,
    };

    // Pass the rowData to the popup component
    setSelectedRow(rowData);
  };


  const columns = [
    {
      name: <span style={{ fontSize: "15px", fontWeight: "600" }}>Activity By</span>,
      selector: row => `${row.First_Name} ${row.Last_Name}`,
      sortable: true
    },
    {
      name: <span style={{ fontSize: "15px", fontWeight: "600" }}>Status</span>,
      selector: row => row.status,
      sortable: true
    },
    {
      name: <span style={{ fontSize: "15px", fontWeight: "600" }}>Send Time</span>,
      selector: row => row.dateTime,
      sortable: true
    },
    {
      name: <span style={{ fontSize: "15px", fontWeight: "600" }}>View</span>,
      cell: (row) => (
        <>
          {

            <button className="btn btn-xs btn-infoss me-2 mt-1" style={{ padding: "6px" }} title="View" onClick={() => handleClick(row)}>
              <i className="fa-solid fa-eye" style={{ color: "white", width: "25px", height: "12px" }}></i>
            </button>

          }
        </>

      ),
    },
  ];

  useEffect(() => {
    const result = activityData.filter((country) => {
      return country.First_Name.toLowerCase().match(search.toLowerCase());
    })
    setFilterSearch(result)
  }, [search])

  useEffect(() => {
    activityGetData();
  }, [])

  return (
    <div className='container'>
      <div className='row user-box-1'>
        <div className='col-lg-12 col-12  d-flex justify-content-center  justify-content-between align-items-center pt-3 pb-3'>
          <h4 className='user-h4 mt-2'>Activity Log</h4>

        </div>
      </div>
      <div className='row d-flex justify-content-center' style={{ background: "#c7d7df", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
        <p className='text-start mt-3 '>Total Activity: {sckelton == true ? <Placeholder as="s" animation="glow"><Placeholder xs={1} /></Placeholder> : <span>{activityLength}</span>} </p>

        <div className='col-lg-12 pb-3 '>
          {tableLoading == true ? <div className="d-flex justify-content-center align-items-center mt-4 " style={{ width: "75%", position: "fixed", zIndex: "20000" }}>
            <div className="spinner"></div>
          </div> :
            <div className="responsive-table">
              <DataTable
                columns={columns}
                data={filterSearch}
                pagination
                fixedHeader
                fixedHeaderScrollHeight='600px'
                highlightOnHover
                subHeader
                theme="solarized"
                striped
                subHeaderComponent={
                  <input type="text" placeholder='Search here' className='w-25 form-control' value={search} onChange={(e) => setSearch(e.target.value)} />
                }

              />
            </div>}
        </div>
        {selectedRow && (
          <ActivityDetailsPopup data={selectedRow} onClose={() => setSelectedRow(null)} />
        )}
      </div>

    </div>
  )
}

export default ActivityLog