import React from 'react';

function ActivityDetailsPopup({ data, onClose }) {
    // Define the styles as JavaScript objects
    const overlayStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '150%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    };

    const contentStyle = {
        width: '500px',
        maxWidth: '90%',
        padding: '20px',
        backgroundColor: 'white',
        borderRadius: '10px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
        textAlign: 'center',
    };

    const paragraphStyle = {
        marginBottom: '20px',
    };

    const buttonStyle = {
        padding: '10px 20px',
        backgroundColor: '#007bff',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    };

    // Check if 'data' is not null or undefined
    if (data) {
        return (
            <div style={overlayStyle}>
                <div style={contentStyle}>
                    <h3 style={{ fontWeight: "600" }}>Activity Details</h3>
                    <p style={paragraphStyle}><strong>Name:</strong> {data.First_Name} {data.Last_Name}</p>
                    <p style={paragraphStyle}><strong>Status:</strong> {data.status}</p>
                    <p style={paragraphStyle}><strong>Send Time:</strong> {data.dateTime}</p>
                    <button style={buttonStyle} onClick={onClose}>
                        Close
                    </button>
                </div>
            </div>
        );
    }

    // Return null when 'data' is not available
    return null;
}

export default ActivityDetailsPopup;
