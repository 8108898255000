import React, { useState } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
  RadioGroup,
  Radio,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Button,
  TextField,
} from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import { FormsData, FusdSubjectData } from "../formData";
import axios from "axios";
import { BACKEND_URI } from "../../../config/config";
import { classPeriod } from "../formData";
export default function FresnoForm({ program }) {
  const [formData, setFormData] = useState(FormsData);
  const [gradeLevel, setGradeLevel] = useState("");
  const [subjects, setSubjects] = useState(FusdSubjectData);
  const [period, setPeriod] = useState();

  const [studentName, setStudentName] = useState("");

  const allSubjects = Object.keys(subjects).slice(0, -1);
  console.log("selected Program", program);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value, // handle checkboxes and other inputs
    }));
  };
  let userData = JSON.parse(localStorage.getItem("studentNest"));
  const { firstName, lastName, address, email, mobileNumber } = userData;
  const token = localStorage.getItem("jwtToken");
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  const handleSubjectChange = (event) => {
    const { name, value, checked, type } = event.target;
    setSubjects((prevSubjects) => ({
      ...prevSubjects,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const body = {
      program,
      name: `${firstName} ${lastName}`,
      email,
      contact: mobileNumber,
      address,
      formData,
      subjects,
      studentName,
    };
    console.log("======body===", body);

    const response = await axios.post(`${BACKEND_URI}/feedback`, body, {
      headers,
    });
    if (response) {
      toast.success("Form Submitted Succesfully");
      setFormData(FormsData);
      setStudentName("");
      setSubjects(FusdSubjectData);
    }
  };
  return (
    <div className="container my-4">
      <h1 className="text-center mb-3">Tutoring Session Form</h1>
      <form
        onSubmit={handleSubmit}
        className="mw-50 mx-auto text-start"
        style={{ maxWidth: "550px" }}
      >
        <div className="form-group mb-3">
          <label className="fw-bold mb-1">Session Date:</label>
          <input
            type="date"
            name="sessionDate"
            value={formData.sessionDate}
            onChange={handleInputChange}
            className="form-control"
            required
          />
        </div>
        <div className="form-group mb-3">
          <label className="fw-bold mb-2">Select Class Period</label>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel className="block">Class Period</InputLabel>
            <Select
              size="small"
              name="period"
              placeholder=""
              value={formData.period}
              input={<OutlinedInput label="Class Period" />}
              onChange={handleInputChange}
              className="form-control"
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              required
            >
              {classPeriod.map((val, index) => (
                <MenuItem value={val.value} key={index}>
                  {val.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className="form-group mb-3">
          <label className="d-block fw-bold mb-1">
            Was this a block period?
          </label>
          <FormControl component="fieldset">
            <RadioGroup
              name="blockPeriod"
              value={formData.blockPeriod}
              onChange={handleInputChange}
              required
            >
              <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="No" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </div>

        <div className="form-group mb-3">
          <label className="d-block fw-bold mb-2">Student Name</label>
          <TextField
            label="Student Name"
            name="studentName"
            size="small"
            value={formData.studentName}
            onChange={handleInputChange}
            className="form-control"
            required
          />
        </div>
        <div className="form-group mb-3">
          <label className="fw-bold mb-2">Select School</label>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel className="block">School Name</InputLabel>
            <Select
              size="small"
              name="schoolName"
              placeholder="School Name"
              value={formData.schoolName}
              input={<OutlinedInput label="School Name" />}
              onChange={handleInputChange}
              className="form-control"
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              required
            >
              <MenuItem value="Bullard High">Bullard High</MenuItem>
              <MenuItem value="Edison High">Edison High</MenuItem>
              <MenuItem value="Fresno High">Fresno High</MenuItem>
              <MenuItem value="Hoover High">Hoover High</MenuItem>
              <MenuItem value="Mclane High">Mclane High</MenuItem>
              <MenuItem value="Roosevelt High">Roosevelt High</MenuItem>
              <MenuItem value="SunnySide High">SunnySide High</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="form-group mb-3">
          <label className="d-block fw-bold mb-1">
            Did the student log in?
          </label>
          <FormControl component="fieldset">
            <RadioGroup
              name="studentLoggedIn"
              value={formData.studentLoggedIn}
              onChange={handleInputChange}
              required
            >
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
              <FormControlLabel
                value="Absent"
                control={<Radio />}
                label="Absent"
              />
            </RadioGroup>
          </FormControl>
        </div>
        {formData.studentLoggedIn === "yes" && (
          <>
            <div className="form-group mb-3">
              <label className="fw-bold mb-2">Select Grade Level</label>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel className="block">Grade Level</InputLabel>
                <Select
                  size="small"
                  name="gradeLevel"
                  value={gradeLevel}
                  input={<OutlinedInput label="grade Level" />}
                  onChange={(e) => setGradeLevel(e.target.value)}
                  className="form-control"
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  required
                >
                  <MenuItem value="8th Grade">8th Grade</MenuItem>
                  <MenuItem value="9th Grade">9th Grade</MenuItem>
                  <MenuItem value="10th Grade">10th Grade</MenuItem>
                  <MenuItem value="11th Grade">11th Grade</MenuItem>
                  <MenuItem value="12th Grade">12th Grade</MenuItem>
                  <MenuItem value="College+">College+</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="form-group mb-3">
              <label className="fw-bold mb-1">Subjects Covered</label>

              <FormGroup>
                {allSubjects.map((subject, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        name={subject}
                        checked={subjects[subject]}
                        onChange={handleSubjectChange}
                        className="form-check"
                      />
                    }
                    label={subject}
                  />
                ))}
              </FormGroup>
              <TextField
                label="Other Subject"
                name="otherSubject"
                size="small"
                value={subjects.otherSubject}
                onChange={handleSubjectChange}
                className="form-control"
              />
            </div>

            <div className="form-group mb-3">
              <label className="fw-bold mb-2">
                Please provide the Content File Name (from the Content Library)
                or provide the name of the activity/topic/skill that was
                covered?
              </label>

              <TextField
                label="Content File Name / Activity / Topic"
                name="contentFileName"
                size="small"
                value={formData.contentFileName}
                onChange={handleInputChange}
                className="form-control"
                required
              />
            </div>

            <div className="form-group mb-3">
              <label className="d-block fw-bold mb-1">
                If the student was in class, how active was the student during
                the specified instruction time?
              </label>
              <FormControl component="fieldset">
                <RadioGroup
                  name="activeStudent"
                  value={formData.activeStudent}
                  onChange={handleInputChange}
                  required
                >
                  <FormControlLabel
                    value="0-15 Minutes"
                    control={<Radio />}
                    label="0-15 Minutes"
                  />
                  <FormControlLabel
                    value="15-30 Minutes"
                    control={<Radio />}
                    label="15-30 Minutes"
                  />
                  <FormControlLabel
                    value="30+ Minutes"
                    control={<Radio />}
                    label="30+ Minutes"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div className="form-group mb-3">
              <label className="d-block fw-bold mb-1">
                Comments on student learning (prompt evidence for student
                learning; 1-2 sentences)
              </label>

              <TextField
                label="Your Answer"
                name="comments"
                size="small"
                value={formData.comments}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
          </>
        )}

        <div className="text-center my-3">
          <Button
            size="medium"
            variant="contained"
            color="primary"
            type="submit"
          >
            Submit
          </Button>
        </div>
      </form>
      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
}
