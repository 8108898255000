import React, { useEffect, useState } from 'react';
import "./Dashboard.css";
import { BACKEND_URI } from "../../config/config"
import Placeholder from 'react-bootstrap/Placeholder';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaUserTie } from "react-icons/fa";
import { FaChalkboardTeacher } from "react-icons/fa";
import { FaUserGraduate } from "react-icons/fa";
import { MdAdminPanelSettings } from "react-icons/md";
import { BsCalendar3 } from "react-icons/bs";


// import graduates from "../../Assets/graduates.png"
function Dashboard() {
    const [teacher, setTeacher] = useState(0)
    const [student, setStudent] = useState(0)
    const [manager, setManager] = useState(0);
    const [employee, setEmployee] = useState(0);
    const [session, setSession] = useState(0);
    const [sckelton, setSckelton] = useState(false);


    const navigate = useNavigate()
    let student_data = JSON.parse(localStorage.getItem("studentNest"));

    const token = localStorage.getItem("jwtToken")
    const headers = {

        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"

    };
    const getData = async () => {
        try {
            setSckelton(true)
            await axios.get(`${BACKEND_URI}/User_Data`, headers).then((resdata) => {
                let teacherarry = []
                let studentarry = []
                let developerarry = []
                let managerArry = [];
                let employeeArry = [];
                let sessionArry = []
                for (var i = 0; i <= resdata.data.length; i++) {
                    if (resdata?.data[i]?.role == "Teacher") {
                        if (resdata?.data[i]?.activeStatus == "true") {
                            sessionArry.push(resdata?.data[i])
                        }
                        let teacherData = resdata?.data[i]
                        teacherarry.push(teacherData);
                    } else if (resdata?.data[i]?.role == "Student") {
                        let studentData = resdata?.data[i]
                        studentarry.push(studentData)
                    } else if (resdata?.data[i]?.role == "Developer") {
                        let developerData = resdata?.data[i]
                        developerarry.push(developerData)
                    } else if (resdata?.data[i]?.role == "Manager") {
                        let managerData = resdata?.data[i];
                        managerArry.push(managerData)
                    } else if (resdata?.data[i]?.role == "Employee") {
                        let employeeData = resdata?.data[i];
                        employeeArry.push(employeeData)
                    }
                }
                setStudent(studentarry.length)
                setTeacher(teacherarry.length)
                setSession(sessionArry.length)
                setManager(managerArry.length)
                setEmployee(employeeArry.length)
                setSckelton(false)
            })
        } catch (e) {
            console.log("e", e);

            setSckelton(false)
        }
    }
    useEffect(() => {
        getData()
    }, [])


    const handleTeacher = () => {
        navigate(`/sidebar/user?selectedRole=${'Teacher'}`)
    };
    const handleManager = () => {
        navigate(`/sidebar/user?selectedRole=${'Manager'}`)
    };
    const handleEmployee = () => {

        navigate(`/sidebar/user?selectedRole=${'Employee'}`)
    };
    const handleClick = () => {

        navigate('/sidebar/schedule')
    };
    return (
        <>
            {student_data.role == "Admin" || student_data.role == "Manager" || student_data.role == "Employee" ?
                (
                    <>
                        <div className='mt-4'>
                            <div className='container  mt-4' style={{ height: "80vh", backgroundColor: "#c7d7df", borderRadius: "10px" }}>
                                <div className='row d-flex justify-content-md-start justify-content-center'>
                                    <div className='col-lg-3 col-11 m-md-4 dashboard-box-1 pt-4 pb-4 text-start d-flex mt-2'>
                                        <div className='min-box ms-3'>
                                            <FaUserGraduate size={25} />
                                        </div>
                                        <div className='ms-2' style={{ lineHeight: "1.5rem" }}>
                                            <span className="two-span">
                                                {sckelton == true ? <Placeholder as="s" animation="glow"><Placeholder xs={12} /></Placeholder> : <span>{student}</span>}
                                            </span><br />
                                            <span className='Order-span'>Total Student</span>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-11 m-md-4 dashboard-box-1 dash-link pt-4 pb-4 text-start d-flex mt-2' onClick={handleTeacher}>
                                        <div className='min-box1 ms-3'>
                                            <FaChalkboardTeacher size={25} />
                                        </div>
                                        <div className='ms-2' style={{ lineHeight: "1.5rem" }}>
                                            <span className="two-span">
                                                {sckelton == true ? <Placeholder as="s" animation="glow"><Placeholder xs={12} /></Placeholder> : <span>{teacher}</span>}

                                            </span><br />
                                            <span className='Order-span'>Total Tutor</span>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-11 m-md-4 dash-link dashboard-box pt-4 pb-4 text-start d-flex mt-2' onClick={handleClick}>
                                        <div className='min-box2 ms-3'>
                                            <BsCalendar3 size={25} />
                                        </div>
                                        <div className='ms-2 ' style={{ lineHeight: "1.5rem" }}>
                                            <span className="two-span">
                                                {sckelton == true ? <Placeholder as="s" animation="glow"><Placeholder xs={12} /></Placeholder>
                                                    : <span>{session}</span>}
                                            </span><br />
                                            <span className='Order-span'>Total Sessions</span>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-11 m-md-4 dash-link dashboard-box-1 pt-4 pb-4 text-start d-flex mt-2' onClick={handleManager}>
                                        <div className='min-box2 ms-3'>
                                            {/* <FcManager size={30} /> */}
                                            <FaUserTie size={25} />
                                        </div>
                                        <div className='ms-2 ' style={{ lineHeight: "1.5rem" }}>
                                            <span className="two-span">
                                                {sckelton == true ? <Placeholder as="s" animation="glow"><Placeholder xs={12} /></Placeholder> : <span>{manager}</span>}
                                            </span><br />
                                            <span className='Order-span'>Total Managers</span>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-11 m-md-4 dash-link dashboard-box pt-4 pb-4 text-start d-flex mt-2' onClick={handleEmployee}>
                                        <div className='min-box2 ms-3'>
                                            <MdAdminPanelSettings size={25} />
                                        </div>
                                        <div className='ms-2' style={{ lineHeight: "1.5rem" }}>
                                            <span className="two-span">
                                                {sckelton == true ? <Placeholder as="s" animation="glow"><Placeholder xs={12} /></Placeholder> : <span>{employee}</span>}

                                            </span><br />
                                            <span className='Order-span'>Total Admin Staff</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className='mt-4'>
                            <div className='container  mt-4' style={{ height: "80vh", backgroundColor: "#c7d7df", borderRadius: "10px" }}>
                                <div className='row d-flex justify-content-md-start justify-content-center'>
                                    <div className='col-lg-3 col-11 m-md-4 dashboard-box pt-4 pb-4 text-start d-flex mt-2'>
                                        <div className='min-box ms-3'>
                                            <FaUserGraduate size={25} />
                                        </div>
                                        <div className='ms-2' style={{ lineHeight: "1.5rem" }}>
                                            <span className="two-span">
                                                {sckelton == true ? <Placeholder as="s" animation="glow"><Placeholder xs={12} /></Placeholder> : <span>{student}</span>}
                                            </span><br />
                                            <span className='Order-span'>Total Student</span>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-11 m-md-4 dashboard-box-1  pt-4 pb-4 text-start d-flex mt-2'>
                                        <div className='min-box1 ms-3'>
                                            <FaChalkboardTeacher size={25} />
                                        </div>
                                        <div className='ms-2' style={{ lineHeight: "1.5rem" }}>
                                            <span className="two-span">
                                                {sckelton == true ? <Placeholder as="s" animation="glow"><Placeholder xs={12} /></Placeholder> : <span>{teacher}</span>}

                                            </span><br />
                                            <span className='Order-span'>Total Tutor</span>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-11 m-md-4 dashboard-box pt-4 pb-4 text-start d-flex mt-2'>
                                        <div className='min-box2 ms-3'>
                                            <BsCalendar3 size={25} />

                                        </div>
                                        <div className='ms-2 ' style={{ lineHeight: "1.5rem" }}>
                                            <span className="two-span">
                                                {sckelton == true ? <Placeholder as="s" animation="glow"><Placeholder xs={12} /></Placeholder>
                                                    : <span>{session}</span>}
                                            </span><br />
                                            <span className='Order-span'>Total Sessions</span>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-11 m-md-4 dashboard-box-1 pt-4 pb-4 text-start d-flex mt-2'>
                                        <div className='min-box2 ms-3'>
                                            <FaUserTie size={25} />
                                        </div>
                                        <div className='ms-2 ' style={{ lineHeight: "1.5rem" }}>
                                            <span className="two-span">
                                                {sckelton == true ? <Placeholder as="s" animation="glow"><Placeholder xs={12} /></Placeholder> : <span>{manager}</span>}
                                            </span><br />
                                            <span className='Order-span'>Total Managers</span>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-11 m-md-4 dashboard-box pt-4 pb-4 text-start d-flex mt-2'>
                                        <div className='min-box2 ms-3'>
                                            <MdAdminPanelSettings size={25} />
                                        </div>
                                        <div className='ms-2' style={{ lineHeight: "1.5rem" }}>
                                            <span className="two-span">
                                                {sckelton == true ? <Placeholder as="s" animation="glow"><Placeholder xs={12} /></Placeholder> : <span>{employee}</span>}

                                            </span><br />
                                            <span className='Order-span'>Total Admin Staff</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        </>

    )
}

export default Dashboard